import { CheckCircleFilled } from '@ant-design/icons';
import { Avatar, Flex } from 'antd';

import { cn } from '~/core/lib/tailwind-util';
import { Organizations } from '~/core/services/UserService/Response/types';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { Weave2024Button } from '~/design-system/components/atoms/Weave2024Button';
import { PrivateRoutes } from '~/router/private-routes';

export interface OrganizationMenuClientItemProps {
  handleSwitchOrganization: (organizationId: string) => void;
  homeOrganizationName: string;
  navigate: (url: string) => void;
  organizations: Organizations[];
  setIsOrganizationMenuOpen: (isOpen: boolean) => void;
}

export const OrganizationMenuClientItem = ({
  handleSwitchOrganization,
  homeOrganizationName,
  navigate,
  organizations,
  setIsOrganizationMenuOpen,
}: OrganizationMenuClientItemProps) => {
  return (
    <Flex className="flex-col">
      <BodyText
        className="mb-2 font-semibold text-weave-secondary"
        data-testid="clientOrganizationHeader"
        size="mini"
      >
        CLIENT ORGANIZATION
      </BodyText>
      <Flex className="flex-col">
        {organizations
          .filter((org) => org.organization.name !== homeOrganizationName)
          .map((org) => (
            <Weave2024Button
              key={org.organization.uuid}
              bypassCapitalization
              data-testid="clientOrganizationItemButton"
              className={cn(
                org.is_selected && 'bg-slate-50',
                `justify-between border-0 py-6 px-4 text-weave-primary font-semibold enabled:hover:text-weave-primary`,
              )}
              ghost
              variant="primary"
              onClick={() => {
                handleSwitchOrganization(org.organization.uuid);
                navigate(PrivateRoutes.DASHBOARD);
                setIsOrganizationMenuOpen(false);
              }}
            >
              <Flex className="overflow-hidden">
                {org.organization?.logo ?
                  <Avatar
                    className="inline-flex mr-2"
                    data-testid="logoAvatar"
                    src={org.organization.logo}
                    size={25}
                  />
                : <Avatar
                    className="width-[25px] inline-flex mr-2"
                    data-testid="letterAvatar"
                    size={25}
                  >
                    <BodyText className="text-white" size="body3">
                      {org.organization.name.charAt(0)}
                    </BodyText>
                  </Avatar>
                }
                <BodyText
                  className="basis-3/4 text-ellipsis overflow-hidden font-semibold"
                  data-testid="organizationName"
                  size="body3"
                >
                  {org.organization.name}
                </BodyText>
              </Flex>

              {org.is_selected && (
                <CheckCircleFilled
                  data-testid="isSelectedCheckmark"
                  className="text-weave-color-product-base"
                />
              )}
            </Weave2024Button>
          ))}
      </Flex>
    </Flex>
  );
};

export default OrganizationMenuClientItem;
