import { HttpStatusCode } from 'axios';

import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { updateSectionContent } from '~/core/api/services/ind-service';
import { IndSection } from '~/core/domain/types';
import {
  getAllSubsectionsFlatFromSectionNumber,
  getSelectedSection,
} from '~/core/lib/ind-utils';
import { TreeUtils } from '~/core/lib/treeUtils';
import logger from '~/core/providers/logger';
import { WeaveResponseHandlers } from '~/core/weave-response-handlers/WeaveResponseHandlers';

import {
  BackEndDocumentApiResponse,
  DocumentLock,
  DocumentSection,
  DocumentTextBlock,
} from '../../domain/types';
import { DocumentEditorServiceMapper } from '../../mappers/responses/mappers';
import { DocumentLockApiResponse } from './responses';

const documentEndpoints = {
  exportToDocx: (indId: string, documentNumber: string) =>
    `ind/${indId}/document/${documentNumber}/export/v2`,
  getSection: `ind-applications/section`,
  getDocument: (indId: string, documentNumber: string) =>
    `ind/${indId}/document/${documentNumber}`,
  getDocumentByVersion: (
    indId: string,
    documentNumber: string,
    version: number,
  ) => `ind/${indId}/document/${documentNumber}/version/${version}`,
  lockSection: (indId: string, docNumber: string) =>
    `ind-applications/${indId}/document/${docNumber}/lock`,
  processImage: (indId: string, docNumber: string) =>
    `ind/${indId}/document/${docNumber}/image`,
};

const indApiAdapter = new IndHttpAdapter();

/**
 * @deprecated - this method (repeated in a number of places) has been centralized in the mapper for
 * document content, and is no longer needed other than for legacy calls or for an individual section call.
 * It will be retired/removed in the very near future.
 */
const createContentTree: (sectionInfo: IndSection) => DocumentSection = (
  sectionInfo: IndSection,
) => {
  // Extract the root node as the subsection, and "content" as the children.
  if (!sectionInfo) return null;

  const theContent = sectionInfo.content;
  const { content, ...theRootElement } = sectionInfo;

  const theRawArray = [theRootElement, ...theContent];
  const theContentTree = TreeUtils.createFromArray({
    sourceArray: theRawArray,
  });
  return theContentTree[0];
};

const containsPendingRefinementPreviews = (
  documentSection: DocumentSection,
) => {
  // Grab all blocks that are text blocks
  const allChildTextBlocks = documentSection.children.filter(
    (block) => block.block_type === 'text',
  );
  // Only text blocks support refinement at this time
  if (allChildTextBlocks.length === 0) return false;

  return (
    allChildTextBlocks.filter(
      (textBlock) => !!(textBlock as DocumentTextBlock).refinement_preview,
    ).length > 0
  );
};

const getDocument: ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => Promise<Array<DocumentSection>> = async ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => {
  const documentResponse = await indApiAdapter.get<BackEndDocumentApiResponse>({
    endpoint: documentEndpoints.getDocument(indId, documentNumber),
    params: {
      ind_id: indId,
      number: documentNumber,
    },
  });

  if (documentResponse.error || !documentResponse.data) {
    return [];
  }

  const documentIsTerminal = documentResponse.data.sections.length === 0;

  const mappedDocumentTree =
    documentIsTerminal ?
      DocumentEditorServiceMapper.map.backendDocumentResponse.to.frontendTerminalDocumentFormat(
        documentResponse.data as BackEndDocumentApiResponse,
      )
    : DocumentEditorServiceMapper.map.backendDocumentResponse.to.frontendDocumentFormat(
        documentResponse.data as BackEndDocumentApiResponse,
      );

  return mappedDocumentTree;
};

const getDocumentSection: ({
  indId,
  sectionNumber,
}: {
  indId: string;
  sectionNumber: string;
}) => Promise<DocumentSection> = async ({
  indId,
  sectionNumber,
}: {
  indId: string;
  sectionNumber: string;
}) => {
  const rawSectionWithContent = await getRawSection({
    indId,
    sectionNumber,
  });
  const sectionContentTree = createContentTree(rawSectionWithContent);
  return sectionContentTree;
};

/**
 * @deprecated - this method is used in the legacy approach to retrieving sections via promise fan-out, where
 * the ind is pulled first and then relevant sections are used.  It will be retired when the revised single-call
 * approach goes live.
 */
const findSelectedSection = ({
  indApp,
  sectionId,
}: {
  indApp: IndApplication;
  sectionId: string;
}) => {
  const selectedSection = getSelectedSection(sectionId, indApp?.modules ?? [])!;
  return selectedSection;
};

/**
 * @deprecated - this method is used in the legacy approach to retrieving sections via promise fan-out, where
 * the ind is pulled first and then relevant sections are used.  It will be retired when the revised single-call
 * approach goes live.
 */
const findRelatedSubsection = ({
  indApp,
  sectionId,
}: {
  indApp: IndApplication;
  sectionId: string;
}) => {
  return getAllSubsectionsFlatFromSectionNumber(
    sectionId,
    indApp?.modules ?? [],
  );
};

const getRawSection = async ({
  indId,
  sectionNumber,
}: {
  indId: string;
  sectionNumber: string;
}) => {
  const sectionInfoResponse = await indApiAdapter.get({
    endpoint: documentEndpoints.getSection,
    params: {
      ind_id: indId,
      section_number: sectionNumber,
    },
  });
  if (sectionInfoResponse.error) {
    return undefined; // Not sure of the best way to handle this
  }

  // Manage the data response here
  const theResponseData = (sectionInfoResponse.data as any).data;
  return theResponseData;
};

const exportToDocx: ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) => Promise<{ url: string } | undefined> = async ({
  indId,
  documentNumber,
}: {
  indId: string;
  documentNumber: string;
}) =>
  WeaveResponseHandlers.mappedData(
    indApiAdapter.post<{ data: { url: string } } | undefined>({
      endpoint: documentEndpoints.exportToDocx(indId, documentNumber),
      data: {
        ind_id: indId,
        section_number: documentNumber,
      },
    }),
    // No idea why the payload has nested data of this nature, but...
    (data) => data?.data,
    undefined,
  );

const saveDocumentSection = async ({
  documentSection,
  indId,
  sectionNumber,
}: {
  documentSection: DocumentSection;
  indId: string;
  sectionNumber: string;
}) => {
  const flattenedBlockList = TreeUtils.flattenTree({
    sourceTree: documentSection,
  }).slice(1);

  const updateBlockEndpointPayload: UpdateBlockEndpointPayload = {
    ind_id: indId,
    section_number: sectionNumber,
    blocks: flattenedBlockList,
  };

  await updateSectionContent(updateBlockEndpointPayload);
};

const lockDocument: ({
  indId,
  docId,
}: {
  indId: string;
  docId: string;
}) => Promise<DocumentLock | undefined> = async ({
  indId,
  docId,
}: {
  indId: string;
  docId: string;
}) => {
  const docLockResponse = await indApiAdapter.get<DocumentLockApiResponse>({
    endpoint: documentEndpoints.lockSection(indId, docId),
    expectDataPayloadAccompanyingErrorCodes: [HttpStatusCode.Locked],
  });

  if (docLockResponse.error) {
    logger.logError('Unforeseen error when attempting to get/set lock', {
      indId: indId,
      docId: docId,
      error: docLockResponse.error,
    });
    return undefined; // In the event of an unforeseen issue, we don't want to block a user from being able to edit.
  }

  if (docLockResponse.data) {
    const theResponseData =
      DocumentEditorServiceMapper.map.documentLockApiResponse.to.documentLock(
        docLockResponse.data,
      );
    return theResponseData;
  }

  logger.logError('No error, but no data found in the payload', {
    indId: indId,
    docId: docId,
  });
  return undefined;
};

const getDocumentByVersion: ({
  indId,
  documentNumber,
  version,
}: {
  indId: string;
  documentNumber: string;
  version: number;
}) => Promise<Array<DocumentSection>> = async ({
  indId,
  documentNumber,
  version,
}: {
  indId: string;
  documentNumber: string;
  version: number;
}) => {
  const documentResponse = await indApiAdapter.get<BackEndDocumentApiResponse>({
    endpoint: documentEndpoints.getDocumentByVersion(
      indId,
      documentNumber,
      version,
    ),
    params: {
      ind_id: indId,
      number: documentNumber,
      version: version,
    },
  });

  if (documentResponse.error) {
    logger.logError(
      `Error: Failed to retrieve document version: ${documentResponse.error}`,
      { error: documentResponse.error },
    );
    return [];
  }

  const mappedDocumentTree =
    DocumentEditorServiceMapper.map.backendDocumentResponse.to.frontendDocumentFormat(
      documentResponse.data as BackEndDocumentApiResponse,
    );

  return mappedDocumentTree;
};

const processImage = async ({
  indId,
  // @ts-expect-error The document number is not used in this function, but is here for consistency with the table templates service call.
  documentNumber,
  sectionNumber,
  positionToInsertImage,
  imageFile,
}: {
  indId: string;
  documentNumber: string;
  sectionNumber: string;
  positionToInsertImage: number;
  imageFile: any;
}) => {
  const imageProcessingResponse = await indApiAdapter.post<any>({
    // @j-weave: This is distinct - while the API for this endpoint expects a "document number", it's
    // really the *section* number we're passing in here to indicate where we're inserting.  Slightly different
    // than the table templates endpoint).  The document number has been left in the API call for FE interface consistency, although
    // not currently used.
    endpoint: documentEndpoints.processImage(indId, sectionNumber),
    data: {
      position: positionToInsertImage,
      image: imageFile,
    },
    headerOverride: {
      'Content-Type': 'multipart/form-data',
      'Content-Disposition': `attachment; filename="${imageFile.name}"`,
    },
  });

  if (imageProcessingResponse.error) {
    return false;
  }

  return true;
};

export {
  containsPendingRefinementPreviews,
  exportToDocx,
  findRelatedSubsection,
  findSelectedSection,
  getDocument,
  getDocumentByVersion,
  getDocumentSection,
  getRawSection,
  lockDocument,
  processImage,
  saveDocumentSection,
};
