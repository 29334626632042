import { TreeDataNode } from '@weavebio/ui-toolkit';
import { Key } from 'react';

import { getModuleForSection } from '~/core/lib/ind-utils';
import { getStatusForSection } from '~/core/lib/sectionStatusUtils';
import { ExtendedTreeDataNode } from '~/core/lib/types';

import { IndTreeFilters } from './types';

/**
 * For example, if we have a section IB 1.14.4.1, we want to return [1, 1.14, 1.14.4]
 * @param key
 * @returns array of Keys of the provided node's Key
 */
export const getParentKeys = (key: Key): Key[] => {
  const keyString = key as string;
  const stringParts: string[] = keyString.split('.');

  const parents: string[] = [];
  for (let i = stringParts.length - 1; i > 0; i--) {
    parents.push(stringParts.slice(0, i).join('.'));
  }

  return parents;
};

/**
 * We need all of the nested children keys to be visible in IND tree filtering so
 * this obtains an array of Keys *only* of the nested children of one particular node
 * @param node
 * @returns array of children Keys
 */
export const getNestedChildrenKeys = (
  node: TreeDataNode,
  filters: IndTreeFilters,
): Key[] => {
  const keys: Key[] = [node.key];

  if (node.children) {
    node.children.forEach((child) => {
      keys.push(...getNestedChildrenKeys(child, filters));
    });
  }

  return keys;
};

// This function is used to create multiple require data structures for INDManagerTable at the same time
// to improve performance:
// 1 - the treeData structure that AntTable requires
// 2 - a flattened array of all nested nodes to be used for filtering
// 3 - a flattened array of only document nodes to be used for the document view
export const buildEctdTableDataFromInd = (
  indTemplate: IndApplication | undefined,
): {
  allNodesFlattened: TreeDataNode[];
  allDocuments: TreeDataNode[];
  tableData: TreeDataNode[];
} => {
  if (!indTemplate)
    return { allNodesFlattened: [], tableData: [], allDocuments: [] };

  const allNodes: TreeDataNode[] = [];
  const allDocuments: TreeDataNode[] = [];

  const convertSectionToTree = (
    sections: IndSectionOutline[],
  ): ExtendedTreeDataNode[] => {
    return sections?.map((section) => {
      const node = {
        key: section.section_number,
        is_document: section.is_document,
        is_supported: !!section.is_supported,
        is_virtual: section.is_virtual,
        is_terminal: section.is_terminal,
        has_content: section.has_content,
        disabled: !section.is_supported,
        section: section,
        section_number: section.section_number,
        children:
          section.subsections?.length ?
            convertSectionToTree(section.subsections)
          : [],
        status: section.status,
        processing_status: section.processing_status,
        dependency_status:
          section.dependency_status ?? section.dependency_status,
        section_title: section.section_title,
        has_prompt_overrides: section.has_prompt_overrides,
        doc_types: section.doc_types ?? [],
      };
      allNodes.push(node);
      if (section.is_document) {
        allDocuments.push(node as TreeDataNode);
      }
      return node;
    });
  };

  const tableData = indTemplate?.modules?.map((module) => {
    const node = {
      key: module.module_number,
      children:
        module.sections?.length ? convertSectionToTree(module.sections) : [],
      disabled: true,
      module_title: module.module_title,
    };
    allNodes.push(node);
    return node;
  });

  return {
    tableData,
    allNodesFlattened: allNodes,
    allDocuments,
  };
};

export const getActualTitleForRecord = (record: TreeDataNode): string => {
  let actual_title = '';

  if ('module_title' in record) {
    actual_title = record['module_title'] as string;
  } else if ('section_title' in record) {
    actual_title = `${record.key} ${record['section_title']}`;
  }

  return actual_title;
};

export const atLeastOneGeneratedSubsection = (
  node: ExtendedTreeDataNode,
): boolean => {
  if (!node.children || node.children.length == 0)
    return node.status === 'Drafting';
  return node.children?.some((child) => {
    const extendedChild = child as ExtendedTreeDataNode;
    if (extendedChild.status === 'Drafting') {
      return true;
    }
    return atLeastOneGeneratedSubsection(extendedChild);
  });
};

/**
 * Module-specific rules for generating a section (or not)
 * @param section the section to evaluate
 * @returns true if the section can be generated, false otherwise
 */
export const canGenerateSectionForModule = (
  section: IndSectionOutline,
): boolean => {
  const moduleNumber = getModuleForSection(section);

  // According to the AI team, we can't use Mode3 generation for Module 3 sections with children
  if (moduleNumber === 3) {
    return section.subsections === null || section.subsections.length === 0;
  }
  return true;
};

/**
 * Determines whether a particular IND record/node matches any of the selected filters
 * @param record an IND node
 * @param filters user selected filters
 * @returns true or false
 */
export const matchesCondition = (
  record: TreeDataNode,
  filters: IndTreeFilters,
): boolean => {
  // Don't ever directly match a module title
  if ('module_title' in record) {
    return false;
  }

  const node = record as ExtendedTreeDataNode;
  const title = getActualTitleForRecord(node);
  if (
    filters.name &&
    // Remove dots to allow something like 267 to match section 2.6.7
    !title
      .replace(/\./g, '')
      .toLowerCase()
      .includes(filters.name.replace(/\./g, '').toLowerCase())
  ) {
    return false;
  }

  const statusStatus = getStatusForSection(node.section);

  if (filters.status && statusStatus !== filters.status) {
    return false;
  }

  return true;
};

export const getDocumentsFromInd = (
  indApp: IndApplication,
): IndSectionOutline[] => {
  const allDocuments: IndSectionOutline[] = [];

  const findDocuments = (sections: IndSectionOutline[]) => {
    sections?.forEach((section) => {
      if (section.subsections?.length) {
        findDocuments(section.subsections);
      }
      if (section.is_document) {
        allDocuments.push(section);
      }
    });
  };

  indApp?.modules?.forEach((module) => {
    findDocuments(module.sections);
  });

  return allDocuments;
};

/**
 * @param docs
 * @param filters
 * @returns filtered documents lolz
 */
export const getFilteredDocuments = (
  docs: TreeDataNode[],
  filters: IndTreeFilters,
): TreeDataNode[] => {
  return docs.filter((doc) => matchesCondition(doc, filters));
};

/**
 *
 * @returns array of options to be used in IndManagerTable status column header
 */
export const getStatusColumnFilterOptions = () => [
  {
    label: 'Drafting',
    value: 'Drafting',
  },
  {
    label: 'Finalized',
    value: 'Finalized',
  },
  {
    label: 'Generating...',
    value: 'Generating',
  },
  {
    label: 'In Review',
    value: 'InReview',
  },
  {
    label: 'In QC',
    value: 'InQC',
  },
  {
    label: 'To Do',
    value: 'ToDo',
  },
  {
    label: 'Not Supported',
    value: 'NotSupported',
  },
  {
    label: 'Not Applicable',
    value: 'NotApplicable',
  },
];
