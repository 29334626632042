import { StatusValue } from '~/design-system/components/molecules/DocumentStatusTag';

import { isProcessing } from '../services/EntityService';

export const getEmptySubsections = (
  section: IndSectionOutline,
): IndSectionOutline[] => {
  const allSubsections: IndSectionOutline[] = [];

  const findSubsections = (s: IndSectionOutline) => {
    s.subsections.forEach((subsection) => {
      if (subsection.status === 'ToDo') {
        allSubsections.push(subsection);
      }
      if (subsection.subsections.length > 0) {
        findSubsections(subsection);
      }
    });
  };

  findSubsections(section);

  return allSubsections;
};

export const getStatusForSection = (
  section: IndSectionOutline,
): StatusValue | null => {
  // This first condition makes sure a status bubbles up to *at most* the document level (per product request)
  if (!section.is_supported && !section.is_document) {
    return null;
  }

  if (isProcessing(section)) {
    return 'ToDo';
  }
  return section.status as StatusValue;
};

export const getStatusForModule = (
  sections: IndSectionOutline[],
): StatusValue => {
  const sectionsStatus = sections.map((section) =>
    getStatusForSection(section),
  );

  // Prioritized statuses. If at least one section has this status, return it
  if (sectionsStatus.includes('Drafting')) return 'Drafting';
  if (sectionsStatus.includes('ToDo')) return 'ToDo';
  if (sectionsStatus.includes('Finalized')) return 'Finalized';
  return 'NotSupported';
};
