import { CheckCircleFilled } from '@ant-design/icons';
import { Avatar, Flex } from 'antd';

import { cn } from '~/core/lib/tailwind-util';
import {
  OrganizationFromProfile,
  Organizations,
} from '~/core/services/UserService/Response/types';
import BodyText from '~/design-system/components/atoms/Text/Body';
import { Weave2024Button } from '~/design-system/components/atoms/Weave2024Button';
import { PrivateRoutes } from '~/router/private-routes';

export interface OrganizationMenuHomeItemProps {
  currentOrganization: OrganizationFromProfile | null;
  findSelectedOrganization: Organizations | null;
  handleSwitchOrganization: (organizationId: string) => void;
  homeOrganizationId: string;
  homeOrganizationLogo: string | null;
  homeOrganizationName: string;
  navigate: (url: string) => void;
  setIsOrganizationMenuOpen: (isOpen: boolean) => void;
}

export const OrganizationMenuHomeItem = ({
  currentOrganization,
  findSelectedOrganization,
  handleSwitchOrganization,
  homeOrganizationId,
  homeOrganizationLogo,
  homeOrganizationName,
  navigate,
  setIsOrganizationMenuOpen,
}: OrganizationMenuHomeItemProps) => {
  return (
    <>
      <BodyText
        className="mb-2 font-semibold text-weave-secondary"
        data-testid="homeOrganizationHeader"
        size="mini"
      >
        HOME ORGANIZATION
      </BodyText>
      <Flex>
        <Weave2024Button
          bypassCapitalization
          className={cn(
            findSelectedOrganization?.is_selected && 'bg-slate-50',
            `w-full justify-between border-0 py-6 px-4 text-weave-primary font-semibold enabled:hover:text-weave-primary`,
          )}
          data-testid="homeOrganizationItemButton"
          ghost
          variant="primary"
          onClick={() => {
            handleSwitchOrganization(homeOrganizationId);
            navigate(PrivateRoutes.DASHBOARD);
            setIsOrganizationMenuOpen(false);
          }}
        >
          <Flex>
            {homeOrganizationLogo ?
              <Avatar
                className="inline-flex mr-2"
                data-testid="logoAvatar"
                size={25}
                src={homeOrganizationLogo}
              />
            : <Avatar
                className="inline-flex mr-2"
                data-testid="letterAvatar"
                size={25}
              >
                <BodyText className="text-white" size="body3">
                  {homeOrganizationName.charAt(0)}
                </BodyText>
              </Avatar>
            }
            <BodyText
              className="basis-3/4 text-ellipsis overflow-hidden font-semibold"
              data-testid="organizationName"
              size="body3"
            >
              {homeOrganizationName}
            </BodyText>
          </Flex>
          {currentOrganization?.name === homeOrganizationName &&
            findSelectedOrganization?.is_selected && (
              <CheckCircleFilled
                data-testid="isSelectedCheckmark"
                className="text-weave-color-product-base"
              />
            )}
        </Weave2024Button>
      </Flex>
    </>
  );
};

export default OrganizationMenuHomeItem;
