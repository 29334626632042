import { useQuery, useQueryClient } from '@tanstack/react-query';

import { QueryKeys } from '~/core/hooks/const';

import * as UserService from '../../../core/services/UserService';

export const useProfileData = () => {
  const queryClient = useQueryClient();

  const { isPending: loadingUserProfile, data: userProfile } = useQuery(
    {
      queryKey: QueryKeys.GET_PROFILE,
      queryFn: async () => UserService.getProfile(),
    },
    queryClient,
  );

  return {
    loadingUserProfile,
    userProfile,
  };
};
