import { Button, ButtonProps } from 'antd';
import { SizeType } from 'antd/es/config-provider/SizeContext';
import { sentenceCase } from 'change-case';

import { cn } from '~/core/lib/tailwind-util';
import { MODAL_BUTTON_TRACKING, TrackingId } from '~/features/tracking/const';

export type Weave2024ButtonVariants =
  | 'default'
  | 'warning'
  | 'review'
  | 'danger'
  | 'primary';

type Props = Omit<ButtonProps, 'size' | 'shape' | 'type'> & {
  children?: React.ReactNode;
  shape?: 'default' | 'round';
  variant?: Weave2024ButtonVariants;
  size?: 'md' | 'lg' | 'sm';
  bypassCapitalization?: boolean;
  noShadow?: boolean;
  trackingId?: TrackingId | MODAL_BUTTON_TRACKING; // eventually to become PARENT_BUTTON_TRACKING once more than the modals are instrumented
};

const indRegex = /\bind\b/gi;

const normalVariantMap: Map<string, string> = new Map<string, string>([
  [
    'default',
    'text-white bg-green-6 enabled:hover:bg-green-5 enabled:hover:border-green-5 focus:bg-green-5 focus:border-green-6 active:bg-green-7',
  ],
  [
    'review',
    'text-white bg-purple-5 enabled:hover:bg-purple-4 enabled:hover:border-purple-4 focus:bg-purple-4 focus:border-purple-5 active:bg-purple-6',
  ],
  [
    'warning',
    'text-white bg-gold-6 enabled:hover:bg-gold-5 enabled:hover:border-gold-5 hover:bg-gold-7 hover:border-gold-7 focus:bg-gold-5 focus:border-gold-5 active:bg-gold-8 active:border-gold-8',
  ],
  [
    'danger',
    'text-white bg-red-6 enabled:hover:bg-red-5 enabled:hover:border-red-5 focus:bg-red-5 focus:border-red-4 active:bg-red-7',
  ],
  [
    'primary',
    'text-neutral-9 bg-neutral-5/[.05] enabled:hover:bg-neutral-4/[.05] enabled:hover:border-neutral-4/[.05] focus:bg-neutral-4/[.05] focus:border-neutral-4/[.05] active:bg-neutral-6/[.05]',
  ],
]);

const ghostVariantMap: Map<string, string> = new Map<string, string>([
  [
    'default',
    'bg-transparent text-green-6 border-green-6 enabled:hover:text-green-5 enabled:hover:border-green-5 focus:border-green-5 focus:text-green-5 active:border-bg-green-7 active:text-bg-green-7 disabled:text-green-3 disabled:border-green-3',
  ],
  [
    'review',
    'bg-transparent text-purple-5 border-purple-5 enabled:hover:text-purple-4 enabled:hover:border-purple-4 focus:text-purple-4 focus:border-purple-4 active:text-purple-6 active:border-purple-6 disabled:text-purple-3 disabled:border-purple-3',
  ],
  [
    'warning',
    'bg-transparent text-gold-6 border-gold-6 enabled:hover:text-gold-5 enabled:hover:border-gold-5 hover:text-gold-7 hover:border-gold-7 focus:text-gold-5 focus:border-gold-5 active:text-gold-8 active:border-gold-8 disabled:text-gold-3 disabled:border-gold-3',
  ],
  [
    'danger',
    'bg-transparent text-red-6 border-red-6 enabled:hover:text-red-5 enabled:hover:border-red-5 focus:border-red-5 focus:text-red-5 active:border-red-7 active:text-red-7 disabled:text-red-3 disabled:border-red-3',
  ],
  [
    'primary',
    'text-neutral-9 bg-transparent enabled:hover:text-neutral-5 enabled:hover:border-neutral-5 focus:text-neutral-5 focus:border-neutral-5 active:text-neutral-6 active:border-neutral-6 disabled:text-neutral-3 disabled:border-neutral-3',
  ],
]);

const sizeMap: {
  md: SizeType;
  lg: SizeType;
  sm: SizeType;
} = {
  md: 'middle',
  lg: 'large',
  sm: 'small',
};

const roundedPaddingMap: {
  md: string;
  lg: string;
  sm: string;
} = {
  md: 'w-2 y-2',
  lg: 'w-3 y-3',
  sm: 'w-1 y-1',
};

export const Weave2024Button = ({
  shape,
  bypassCapitalization = false,
  size = 'md',
  ghost,
  variant = 'default',
  className,
  noShadow,
  trackingId,
  disabled,
  ...rest
}: Props) => {
  // @j-weave: after we've done a complete conversion, trackingId will no longer be optional on Weave2024Button; for now
  // I've started by forcing the convention on parent components like the modals
  const theDataTrackingId: string =
    typeof trackingId === 'string' ? trackingId : (
      `${trackingId?.id}_${trackingId?.action}`
    );

  if (typeof rest.children === 'string' && !bypassCapitalization) {
    return (
      <Button
        size={sizeMap[size]}
        className={cn(
          noShadow ? 'shadow-none' : 'shadow-weave-shadow-button',
          disabled ? 'bg-[#1B202B/4%]'
          : ghost ? ghostVariantMap.get(variant)
          : normalVariantMap.get(variant),
          shape === 'round' ? 'rounded-full' : 'rounded-md',
          className,
        )}
        data-tracking-id={theDataTrackingId}
        disabled={disabled}
        {...rest}
      >
        {sentenceCase(rest.children).replace(indRegex, 'IND')}
      </Button>
    );
  }

  return (
    <Button
      size={sizeMap[size]}
      shape={shape}
      className={cn(
        noShadow ? 'shadow-none' : 'shadow-weave-shadow-button',
        disabled ? 'bg-[#1B202B/4%]'
        : ghost ? ghostVariantMap.get(variant)
        : normalVariantMap.get(variant),
        shape === 'round' ?
          cn('rounded-full', roundedPaddingMap[size])
        : 'rounded-md',
        className,
      )}
      disabled={disabled}
      data-tracking-id={theDataTrackingId}
      {...rest}
    >
      {rest.children}
    </Button>
  );
};
