import { IndProfile } from '~/core/domain/types';

import { UserReportingProvider } from '..';

export class PendoUserReportingProvider implements UserReportingProvider {
  identify: (profile: IndProfile) => void = (profile) => {
    const enablePendo = !!import.meta.env.VITE_ENABLE_PENDO;
    if (enablePendo && pendo) {
      pendo.initialize({
        visitor: {
          id: profile.uuid,
          email: profile.email,
          firstName: profile.firstName,
          lastName: profile.lastName,
          role: profile.role,
          indId: profile.inds[0]?.id,
          friendlyName: profile.friendlyName,
        },

        account: {
          id: profile.organization.uuid,
          accountName: profile.organization.name,
        },
      });
    }
  };

  resetIdentification: () => void = () => {
    // Apparently unsupported by Pendo
  };
}
