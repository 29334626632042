import { IndProfile } from '~/core/domain/types';
import { FriendlyNameMapper } from '~/core/mappers/friendlyNameMapper';

import { ProfileEndpointApiResponse } from './types';

const mapProfileApiResponseToIndProfile = (
  apiResponse: ProfileEndpointApiResponse,
): IndProfile => {
  return {
    firstName: apiResponse.first_name,
    lastName: apiResponse.last_name,
    friendlyName:
      FriendlyNameMapper.map.profileResponseData.to.friendlyName(apiResponse),
    uuid: apiResponse.uuid,
    role: apiResponse.role,
    organization: apiResponse.organization,
    organizations: apiResponse.organizations,
    inds: apiResponse.inds,
    email: apiResponse.email,
  };
};

export const UserServiceMapper = {
  map: {
    profileApiResponse: {
      to: {
        indProfile: mapProfileApiResponseToIndProfile,
      },
    },
  },
};
