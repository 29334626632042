import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import logger from '~/core/providers/logger';

const indEndpoints = {
  inds: `ind-applications`,
  ind: (indId: string) => `ind-applications/${indId}`,
  getIndLocks: (indId: string) => `ind-applications/${indId}/locks`,
  documentSummaries: (indId: string) =>
    `ind-applications/${indId}/document-type-summaries`,
};

const indApiAdapter = new IndHttpAdapter();

const getAllInds: () => Promise<Array<IndApplication>> = async () => {
  const indResponse = await indApiAdapter.get<{ data: Array<IndApplication> }>({
    endpoint: indEndpoints.inds,
  });
  if (indResponse.error || !indResponse.data) {
    const indError = indResponse.error || { message: 'missing data' };
    logger.logError(indError.message, {
      ...indError,
    });
    return [];
  }

  // This should theoretically never be undefined - if an error occurred we're returning early
  return indResponse.data?.data as Array<IndApplication>;
};

const getInd: (indId: string) => Promise<IndApplication | undefined> = async (
  indId: string,
) => {
  const indResponse = await indApiAdapter.get<{ data: IndApplication }>({
    endpoint: indEndpoints.ind(indId),
  });
  if (indResponse.error) {
    logger.logError(indResponse.error.message, {
      ...indResponse.error,
    });
    return undefined;
  }

  return indResponse.data?.data as IndApplication;
};

const createInd: (
  indData: Partial<IndApplication>,
) => Promise<IndApplication | undefined> = async (indData) => {
  const indResponse = await indApiAdapter.post<IndApplication>({
    endpoint: indEndpoints.inds,
    data: indData,
  });
  if (indResponse.error) {
    logger.logError(indResponse.error.message, {
      ...indResponse.error,
    });
    return undefined;
  }

  return indResponse.data as IndApplication;
};

const updateInd: (
  indId: string,
  updatedIndData: Partial<IndApplication>,
) => Promise<IndApplication> = async (indId, updatedIndData) => {
  const indResponse = await indApiAdapter.put<IndApplication>({
    endpoint: indEndpoints.ind(indId),
    data: updatedIndData,
  });

  if (indResponse.error) {
    logger.logError(indResponse.error.message, {
      ...indResponse.error,
    });
  }

  return indResponse.data as IndApplication;
};

const getIndLocks: (
  indId: string,
) => Promise<Map<string, { email: string; uuid: string }>> = async (
  indId: string,
) => {
  const indLockResponse = await indApiAdapter.get<
    Array<{ documentNumber: string; uuid: string; email: string }>
  >({
    endpoint: indEndpoints.getIndLocks(indId),
  });
  const lockMap = new Map<string, { email: string; uuid: string }>();

  if (indLockResponse.error) {
    logger.logError(indLockResponse.error.message, {
      ...indLockResponse.error,
    });
    return lockMap;
  }

  const rawArray = indLockResponse.data as Array<{
    documentNumber: string;
    email: string;
    uuid: string;
  }>;
  for (const entry of rawArray) {
    lockMap.set(entry.documentNumber, {
      uuid: entry.uuid,
      email: entry.email,
    });
  }
  return lockMap;
};

const deleteInd: (indId: string) => Promise<boolean> = async (
  indId: string,
) => {
  const indResponse = await indApiAdapter.delete<IndApplication>({
    endpoint: indEndpoints.ind(indId),
  });
  if (indResponse.error) {
    logger.logError(indResponse.error.message, {
      ...indResponse.error,
    });
    return false;
  }

  return true;
};

const getDocumentTypeSummaries = async ({
  indId,
  data,
}: {
  indId: string;
  data: { sections: Record<string, string | number>[] };
}) => {
  const response = await indApiAdapter.post({
    endpoint: indEndpoints.documentSummaries(indId),
    data,
  });

  if (response.error || !response.data) {
    const error = response.error || { message: 'missing doc summaries data' };
    logger.logError(error.message, {
      ...error,
    });
    return [];
  }

  return response.data;
};

export {
  createInd,
  deleteInd,
  getAllInds,
  getDocumentTypeSummaries,
  getInd,
  getIndLocks,
  updateInd,
};
