import { useMutation, useQueryClient } from '@tanstack/react-query';

import { cancelContentGeneration } from '~/core/api/services/ind-service';
import { QueryKeys } from '~/core/hooks/const';

type Props = {
  onSettled?: () => void;
};

export const useCancelContentGeneration = (props: Props | void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (params: CancelContentGenerationParams) =>
      cancelContentGeneration(params),
    onSuccess: (_, variables) => {
      variables.sectionNumbers.forEach((sectionId) => {
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_SECTION_INFO({
            sectionId,
            indId: variables.indId,
          }),
        });
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_SECTION_STATUS({
            sectionNumber: sectionId,
            indId: variables.indId,
          }),
        });
        queryClient.invalidateQueries({
          queryKey: QueryKeys.GET_SUBSECTIONS({
            sectionId,
            indId: variables.indId,
          }),
        });
      });
    },
    onSettled: props?.onSettled,
  });
};
