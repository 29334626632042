import { TreeDataNode } from '@weavebio/ui-toolkit';

import { ExtendedTreeDataNode } from './types';

const DOCUMENT_REVIEW_STATUS = ['InReview', 'InQC'];
const ACTIVE_REVIEWING_STATUS = ['InReview', 'Pending'];

const DOCUMENT_STATUS_TO_REVIEW_TYPE: Record<string, 'review' | 'qc'> = {
  InReview: 'review',
  InQC: 'qc',
};

export const getCurrentReviewerForSection = (
  section: IndSectionOutline,
): SectionReview[] => {
  return (
    section.reviews.filter(
      (review) =>
        ACTIVE_REVIEWING_STATUS.includes(review.status) &&
        DOCUMENT_STATUS_TO_REVIEW_TYPE[section.status] === review.review_type,
    ) || []
  );
};

export const isUserCurrentReviewer = (
  userId: string,
  reviews: SectionReview[],
  review_type?: 'review' | 'qc',
): boolean => {
  return reviews.some(
    (reviewer) =>
      reviewer.user === userId &&
      ACTIVE_REVIEWING_STATUS.includes(reviewer.status) &&
      reviewer.review_type == review_type,
  );
};

/**
 * Filters documents assigned for review by a specific user ID.
 * @param {string} userId The ID of the user.
 * @param {TreeDataNode[]} allDocs All documents to filter from.
 * @returns {TreeDataNode[]} An array of documents assigned to the user.
 */
export const getAssignedDocsForReviewByUserId = (
  userId: string,
  allDocs: TreeDataNode[],
): TreeDataNode[] => {
  if (!userId.trim()) return [];

  const assignedDocs = allDocs.filter((doc) => {
    const node: ExtendedTreeDataNode = doc as ExtendedTreeDataNode;
    const currentReviewers = getCurrentReviewerForSection(node.section);

    return (
      DOCUMENT_REVIEW_STATUS.includes(node.status) &&
      isUserCurrentReviewer(
        userId,
        currentReviewers,
        DOCUMENT_STATUS_TO_REVIEW_TYPE[node.status],
      )
    );
  });

  return assignedDocs;
};

// Same func as above, but for IndSection type
export const getAssignedDocsForReviewByUserIdAsIndSection = (
  userId: string,
  allDocs: IndSectionOutline[],
): IndSectionOutline[] => {
  if (!userId.trim()) return [];

  const assignedDocs = allDocs.filter((doc) => {
    const currentReviewers = getCurrentReviewerForSection(doc);

    return (
      DOCUMENT_REVIEW_STATUS.includes(doc.status) &&
      isUserCurrentReviewer(
        userId,
        currentReviewers,
        DOCUMENT_STATUS_TO_REVIEW_TYPE[doc.status],
      )
    );
  });

  return assignedDocs;
};
