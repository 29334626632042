import { HttpStatusCode } from 'axios';

import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import logger from '~/core/providers/logger';
import {
  IndInvitation,
  IndInvitationResult,
} from '~/features/organization-management/domain/types';

const invitationServiceEndpoints = {
  acceptInvitation: `invitations/accept/`,
  sendInvitation: `invitations/send/`,
  resendInvitation: `invitations/resend/`,
  getInvitation: (invitationId: string) => `invitations/detail/${invitationId}`,
  invalidateInvitation: `invitations/rescind/`,
};

type GetInvitationApiResponse = {
  organization_id: string;
  organization_name: string;
  email_address: string;
  invitation_id: string;
};

const indApiAdapter = new IndHttpAdapter();

const acceptInvite: ({
  invitationId,
  firstName,
  lastName,
  password,
}: {
  invitationId: string;
  firstName: string;
  lastName: string;
  password: string;
}) => Promise<{
  invitationId: string;
  firstName: string;
  lastName: string;
  userId: string;
}> = async ({ invitationId, firstName, lastName, password }) => {
  const acceptInviteResponse = await indApiAdapter.post({
    endpoint: invitationServiceEndpoints.acceptInvitation,
    data: {
      first_name: firstName,
      last_name: lastName,
      password: password,
      invitation_id: invitationId,
    },
  });

  if (acceptInviteResponse.error) {
    logger.logError(acceptInviteResponse.error.message, {
      ...acceptInviteResponse.error,
    });
    throw Error(acceptInviteResponse.error.message);
  }

  const acceptInviteResponseData = acceptInviteResponse.data as any;
  const mappedResponse: {
    invitationId: string;
    userId: string;
    firstName: string;
    lastName: string;
  } = {
    invitationId: acceptInviteResponseData.invitation_id,
    userId: acceptInviteResponseData.user_id,
    firstName: acceptInviteResponseData.first_name,
    lastName: acceptInviteResponseData.last_name,
  };
  return mappedResponse;
};

const getInvitation: ({
  invitationId,
}: {
  invitationId: string;
}) => Promise<IndInvitation> = async ({ invitationId }) => {
  const invitationResponse = await indApiAdapter.get<GetInvitationApiResponse>({
    endpoint: invitationServiceEndpoints.getInvitation(invitationId),
  });
  if (invitationResponse.error) {
    logger.logError(invitationResponse.error.message, {
      ...invitationResponse.error,
    });
    return {
      id: '',
      orgId: '',
      orgName: '',
      email: '',
    };
  }

  const invitationData = invitationResponse.data as GetInvitationApiResponse;
  const invite: IndInvitation = {
    id: invitationData.invitation_id,
    orgId: invitationData.organization_id,
    orgName: invitationData.organization_name,
    email: invitationData.email_address,
  };
  return invite;
};

const invalidateInvitation: ({
  invitationId,
}: {
  invitationId: string;
}) => Promise<boolean> = async ({ invitationId }) => {
  const invalidateUserResponse = await indApiAdapter.post({
    endpoint: invitationServiceEndpoints.invalidateInvitation,
    data: {
      invitation_id: invitationId,
    },
  });

  if (invalidateUserResponse.error) {
    logger.logError(invalidateUserResponse.error.message, {
      ...invalidateUserResponse.error,
    });
    throw Error();
  }

  return true;
};

const resendInvitation: ({
  userId,
}: {
  userId: string;
}) => Promise<IndInvitation | undefined> = async ({ userId }) => {
  const invitationResponse = await indApiAdapter.post({
    endpoint: invitationServiceEndpoints.resendInvitation,
    data: {
      user_id: userId,
    },
  });
  if (invitationResponse.error) {
    logger.logError(invitationResponse.error.message, {
      ...invitationResponse.error,
    });
    return {};
  }

  const invitationData = (invitationResponse.data as any).data;
  return invitationData;
};

const sendInvitations: ({
  emailAddresses,
  organizationId,
  orgRoleKey,
}: {
  emailAddresses: string[];
  organizationId: string;
  orgRoleKey: string;
}) => Promise<IndInvitationResult[]> = async ({
  emailAddresses,
  organizationId,
  orgRoleKey,
}) => {
  const invitationSendRequests = emailAddresses.map((emailAddress) => {
    return indApiAdapter.post({
      endpoint: invitationServiceEndpoints.sendInvitation,
      data: {
        email_address: emailAddress,
        organization_id: organizationId,
        role: orgRoleKey,
      },
      expectDataPayloadAccompanyingErrorCodes: [
        HttpStatusCode.UnprocessableEntity,
      ],
    });
  });

  const invitationResponses = await Promise.all(invitationSendRequests);
  const erroredResponses = invitationResponses.filter(
    (invitationResponse) => !!invitationResponse.error,
  );

  if (erroredResponses.length > 0) {
    const errorContextArray = erroredResponses.map((err) => {
      return { ...err.error };
    });
    logger.logError('Some errors were encountered during sending invites.', {
      errors: errorContextArray,
    });

    const usersAlreadyInAnOrganization = erroredResponses.filter(
      (errResp) => errResp.error?.status === HttpStatusCode.UnprocessableEntity,
    );

    if (usersAlreadyInAnOrganization.length > 0) {
      const invites = usersAlreadyInAnOrganization
        .map((invResponse) => (invResponse.data as any).data)
        .map((dataResp) => {
          return {
            ...dataResp,
            status: 'already-invited',
          };
        });
      return invites;
    }

    return [];
  }

  const invites = invitationResponses
    .filter((invitationResponse) => !invitationResponse.error)
    .map((invResponse) => (invResponse.data as any).data)
    .map((dataResp) => {
      return {
        ...dataResp,
        status: 'success',
      };
    });
  return invites;
};

export {
  acceptInvite,
  getInvitation,
  invalidateInvitation,
  resendInvitation,
  sendInvitations,
};
