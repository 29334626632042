import { Key } from 'react';

import { IndHttpAdapter } from '~/core/adapters/indHttpAdapter';
import { DateUtils } from '~/core/lib/DateUtils';
import logger from '~/core/providers/logger';
import {
  DefaultOrgRoles,
  DossierTree,
  OrganizationMember,
  OrganizationRoleDetail,
  OrganizationStats,
} from '~/features/organization-management/domain/types';
import {
  Organization,
  OrganizationRoles,
  OrgRoleData,
  OrgRoleId,
  UserPermissions,
  UserRoleData,
} from '~/features/organization-management/domain/types';

import { OrganizationServiceMapper } from './Response/mappers';
import {
  GetOrganizationDetailsResponse,
  GetOrganizationIndsResponse,
} from './Response/types';

const organizationServiceEndpoints = {
  getMembers: (orgId: string) => `organization/${orgId}/members`,
  getOrganization: `organization/detail/`,
  removeMember: `organization/remove-member/`,
  getOrganizationInds: `organization/inds/`,
  getOrganizationRoles: 'authorization/org-roles', // gets all roles for an organization
  addOrganizationRole: 'authorization/org-role', // post for new role on organization
  updateOrganizationRole: (orgId: string) => `authorization/org-role/${orgId}`, // this endpoint handles put / delete an orgs role
  getOrganizationRoleDetails: (orgRoleId: string) =>
    `authorization/org-role-detail/${orgRoleId}`, // this endpoint gets a role's details
  getUserPermissions: 'authorization/user-permissions', // this endpoint gets the user's permissions map
  updateUserRoles: 'authorization/user-roles', // this endpoint update's a user's role
  getDossierTree: 'authorization/default-blueprint-access', // this endpoint gets the default dossier for creating a role
  switchOrganizations: 'account/switch-organization', // this endpoint handles organization switching
};

const indApiAdapter = new IndHttpAdapter();

const getMembers: () => Promise<OrganizationMember[]> = async () => {
  const membersResponse =
    await indApiAdapter.get<GetOrganizationDetailsResponse>({
      endpoint: organizationServiceEndpoints.getOrganization,
    });

  if (membersResponse.error) {
    logger.logError(membersResponse.error.message, {
      ...membersResponse.error,
    });
    return [];
  }

  const orgDetails =
    OrganizationServiceMapper.map.getOrganizationDetailResponse.to.organization(
      membersResponse.data as GetOrganizationDetailsResponse,
    );
  return orgDetails.members.filter(
    (member) => !['invalid', 'rescinded'].includes(member.status),
  );
};

const getOrganization: () => Promise<Organization> = async () => {
  const orgResponse = await indApiAdapter.get({
    endpoint: organizationServiceEndpoints.getOrganization,
  });
  if (orgResponse.error) {
    logger.logError(orgResponse.error.message, { ...orgResponse.error });
    return {
      id: '',
      slug: '',
      name: '',
      type: '',
      createdAt: DateUtils.null(),
      updatedAt: DateUtils.null(),
      members: [],
    };
  }
  const organization =
    OrganizationServiceMapper.map.getOrganizationDetailResponse.to.organization(
      orgResponse.data as GetOrganizationDetailsResponse,
    );
  return organization;
};

const getOrgStats: () => Promise<OrganizationStats> = async () => {
  const getOrgIndsPromise = indApiAdapter.get<GetOrganizationIndsResponse>({
    endpoint: organizationServiceEndpoints.getOrganizationInds,
  });
  const getOrgPromise = getOrganization();
  const results = await Promise.all([getOrgIndsPromise, getOrgPromise]);
  if (results[0].error) {
    logger.logError(results[0].error.message, {
      ...results[0].error,
    });
    return {
      name: results[1].name,
      totalInds: 0,
      totalUsers: results[1].members.length,
    };
  }
  return {
    totalInds: (results[0].data as GetOrganizationIndsResponse).inds.length,
    totalUsers: results[1].members.length,
    name: results[1].name,
  };
};

const removeUser: ({
  userId,
}: {
  userId: string;
}) => Promise<boolean> = async ({ userId }) => {
  const removeUserResponse = await indApiAdapter.post({
    endpoint: organizationServiceEndpoints.removeMember,
    data: {
      user_id: userId,
    },
  });

  if (removeUserResponse.error) {
    logger.logError(removeUserResponse.error.message, {
      ...removeUserResponse.error,
    });
    throw Error();
  }

  return true;
};

const getOrganizationRoles: () => Promise<OrganizationRoles[]> = async () => {
  const response = await indApiAdapter.get<OrganizationRoles[]>({
    endpoint: organizationServiceEndpoints.getOrganizationRoles,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }

  return response.data as OrganizationRoles[];
};

const getOrganizationRoleDetails: (
  orgRoleId: string,
) => Promise<OrganizationRoleDetail> = async (orgRoleId) => {
  const response = await indApiAdapter.get<OrganizationRoleDetail>({
    endpoint:
      organizationServiceEndpoints.getOrganizationRoleDetails(orgRoleId),
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }

  return response.data as OrganizationRoleDetail;
};

const addOrganizationRole: ({
  roleName,
  dossierAccess, // blueprint_access_list
  roleDescription,
  roleKey,
}: {
  roleName: string;
  dossierAccess: string[];
  roleDescription?: string;
  roleKey?: DefaultOrgRoles;
}) => Promise<OrgRoleData> = async ({
  roleName,
  dossierAccess,
  roleDescription,
  roleKey,
}) => {
  const response = await indApiAdapter.post<OrgRoleData>({
    endpoint: organizationServiceEndpoints.addOrganizationRole,
    data: {
      name: roleName,
      blueprint_access_list: dossierAccess,
      ...(roleDescription && { description: roleDescription }),
      ...(roleKey && { clone_permissions_role_key: roleKey }),
    },
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(response.error.message);
  }

  return response.data as OrgRoleData;
};

const updateOrganizationRole: ({
  roleName,
  dossierAccess,
  orgRoleId,
  roleDescription,
}: {
  roleName: string;
  dossierAccess: Key[];
  orgRoleId: string;
  roleDescription?: string;
}) => Promise<OrgRoleData & OrgRoleId> = async ({
  roleName,
  dossierAccess,
  orgRoleId,
  roleDescription,
}) => {
  const response = await indApiAdapter.put<OrgRoleData & OrgRoleId>({
    endpoint: organizationServiceEndpoints.updateOrganizationRole(orgRoleId),
    data: {
      name: roleName,
      blueprint_access_list: dossierAccess,
      ...(roleDescription && { description: roleDescription }),
    },
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(response.error.message);
  }

  return response.data as OrgRoleData & OrgRoleId;
};

const deleteOrganizationRole: ({
  orgRoleId,
}: {
  orgRoleId: string;
}) => Promise<OrgRoleId> = async ({ orgRoleId }) => {
  const response = await indApiAdapter.delete<OrgRoleId>({
    endpoint: organizationServiceEndpoints.updateOrganizationRole(orgRoleId),
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
    throw Error(response.error.message);
  }

  return response.data as OrgRoleId;
};

const getUserPermissions: () => Promise<UserPermissions[]> = async () => {
  const response = await indApiAdapter.get<UserPermissions[]>({
    endpoint: organizationServiceEndpoints.getUserPermissions,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }

  return response.data as UserPermissions[];
};

const updateUserRoles: ({
  userId,
  roleId,
}: {
  userId: string;
  roleId: string;
}) => Promise<UserRoleData> = async ({ userId, roleId }) => {
  const response = await indApiAdapter.put<UserRoleData>({
    endpoint: organizationServiceEndpoints.updateUserRoles,
    data: { user_id: userId, role_id: roleId },
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data as UserRoleData;
};

const getDossierTree: () => Promise<DossierTree> = async () => {
  const response = await indApiAdapter.get<DossierTree>({
    endpoint: organizationServiceEndpoints.getDossierTree,
  });

  if (response.error) {
    logger.logError(response.error.message, { ...response.error });
  }

  return response.data as DossierTree;
};

const switchOrganizations: ({
  organizationId,
}: {
  organizationId: string;
}) => Promise<any> = async ({ organizationId }) => {
  const response = await indApiAdapter.put<any>({
    endpoint: organizationServiceEndpoints.switchOrganizations,
    data: { organization_id: organizationId },
  });

  if (response.error) {
    logger.logError(response.error.message, {
      ...response.error,
    });
    throw Error(response.error.message);
  }

  return response.data;
};

export {
  addOrganizationRole,
  deleteOrganizationRole,
  getDossierTree,
  getMembers,
  getOrganization,
  getOrganizationRoleDetails,
  getOrganizationRoles,
  getOrgStats,
  getUserPermissions,
  removeUser,
  switchOrganizations,
  updateOrganizationRole,
  updateUserRoles,
};
