import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

export type ReportedGenerationError = {
  indId: string;
  lastUpdatedAt: string;
  section: string;
};
interface LocalStorageStore {
  lastOpenedDocumentSectionNumber: string;
  lastOpenedTemplateSection: string;
  reportedGenerationErrors: ReportedGenerationError[];
  setReportedGenerationErrors: (
    reportedGenerationErrors: ReportedGenerationError[],
  ) => void;
  setLastOpenedDocumentSectionNumber: (
    lastOpenedDocumentSectionNumber: string,
  ) => void;
  setLastOpenedTemplateSection: (lastOpenedTemplateSection: string) => void;
}

const useLocalStorageStore = create<LocalStorageStore>()(
  devtools(
    persist(
      (set, get) => ({
        lastOpenedDocumentSectionNumber: '',
        setLastOpenedDocumentSectionNumber: (
          lastOpenedDocumentSectionNumber,
        ) => {
          if (
            get().lastOpenedDocumentSectionNumber ===
            lastOpenedDocumentSectionNumber
          ) {
            return;
          }

          return set({
            lastOpenedDocumentSectionNumber: lastOpenedDocumentSectionNumber,
          });
        },
        lastOpenedTemplateSection: '',
        setLastOpenedTemplateSection: (lastOpenedTemplateSection: string) => {
          if (get().lastOpenedTemplateSection === lastOpenedTemplateSection) {
            return;
          }

          return set({
            lastOpenedTemplateSection: lastOpenedTemplateSection,
          });
        },
        reportedGenerationErrors: [],
        setReportedGenerationErrors: (reportedGenerationErrors) => {
          return set({ reportedGenerationErrors });
        },
      }),
      {
        name: 'last-openned-document-section-number',
      },
    ),
  ),
);

export const useLastOpenedDocumentSectionNumber = () =>
  useLocalStorageStore((state) => state.lastOpenedDocumentSectionNumber);
export const useLastOpenedTemplateSection = () =>
  useLocalStorageStore((state) => state.lastOpenedTemplateSection);
export const useReportedGenerationErrors = () =>
  useLocalStorageStore((state) => state.reportedGenerationErrors);

export const useLocalStorageStoreActions = () =>
  useLocalStorageStore((state) => ({
    setLastOpenedDocumentSectionNumber:
      state.setLastOpenedDocumentSectionNumber,
    // @j-weave: I think we should likely be pairing state getters/setters in the store,
    // with a provider or hook accessing this store (vs. where these typically interact directly at the page level).
    // I've left this consistent with the existing patterning prior to a refactoring.
    setLastOpenedTemplateSection: state.setLastOpenedTemplateSection,
    setReportedGenerationErrors: state.setReportedGenerationErrors,
  }));
