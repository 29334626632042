import { App } from '@weavebio/ui-toolkit';

import {
  Banner,
  BannerType,
} from '~/design-system/components/molecules/Banner';

export type ToastProps = {
  message: string;
  key?: string;
  duration?: number;
  position?: 'top' | 'bottom';
  action?: { text: string; onAction: () => void };
};

const ToastBannerContainer = ({ children }: { children: React.ReactNode }) => {
  return <div className="max-w-[768px] z-[9999]">{children}</div>;
};

const isToastProps = (props: ToastProps | string): props is ToastProps => {
  return (props as ToastProps).message !== undefined;
};

export const useToast = () => {
  const { message } = App.useApp();

  const setUpToast =
    (bannerType: BannerType) => (props: ToastProps | string) => {
      if (!isToastProps(props)) {
        const content = props as string;
        return message.open({
          key: content,
          content: (
            <ToastBannerContainer>
              <Banner
                bannerType={bannerType}
                message={content}
                onClickClose={() => {
                  message.destroy(content);
                }}
              />
            </ToastBannerContainer>
          ),
          className: `weave-${bannerType}-banner`,
        });
      }

      const position = props.position ?? 'top';

      const key = props.key || props.message;
      return message.open({
        key: key,
        duration: props.duration,
        content: (
          <ToastBannerContainer>
            <Banner
              bannerType={bannerType}
              message={props.message}
              onClickClose={() => {
                message.destroy(key);
              }}
              action={props.action}
            />
          </ToastBannerContainer>
        ),
        className: `weave-${bannerType}-banner ${
          position === 'bottom' ? 'weave-bottom-banner' : ''
        }`,
      });
    };

  const toast = {
    success: setUpToast('success'),
    error: setUpToast('error'),
    info: setUpToast('neutral'),
    neutral: setUpToast('neutral'),
    warning: setUpToast('warning'),
    // @j-weave: I've left this in for backwards compat, but this doesn't really make too much sense here.
    // We should look into removing it
    destroy: ({ key }: { key: string }) => {
      return message.destroy(key);
    },
  };

  return toast;
};
