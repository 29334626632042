import { Permissions } from '~/features/organization-management/domain/types';
import { useOrganizationData } from '~/features/organization-management/hooks/useOrganizationData';

export type PermissionsMap = Record<string, Permissions>;
export type HasPermission = {
  securedEntity: string;
  documentId: string | null;
  indId: string | null;
};

export const useUserPermissionsMap = () => {
  const { userPermissions = [] } = useOrganizationData();
  const permissionsMap: PermissionsMap = {};

  userPermissions.forEach((entity) => {
    return (permissionsMap[entity.secured_entity as keyof Permissions] =
      entity.permissions);
  });

  const hasPermission = ({
    securedEntity,
    documentId,
    indId,
  }: HasPermission) => {
    const primaryKey = `${securedEntity}:ind.Document:${documentId}`;
    const secondaryKey = `${securedEntity}:ind.Ind:${indId}`;
    const defaultKey = `${securedEntity}`;

    if (permissionsMap[primaryKey]) return permissionsMap[primaryKey];
    if (permissionsMap[secondaryKey]) return permissionsMap[secondaryKey];

    return permissionsMap[defaultKey];
  };

  return { hasPermission, permissionsMap };
};
