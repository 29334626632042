const PROCESSING_STATUSES = ['InQueue', 'Generating'];
const DRAFTED_STATUSES = ['Drafting', 'InReview', 'InQC', 'Finalized'];

const NO_CONTENT_STATUSES = ['ToDo', 'NotSupported', 'NotApplicable'];

/**
 * Determines whether an entity with a processing status is in process - meaning, is this being worked on by the system and thus
 * otherwise unavailable for editing/viewing until complete.
 * @param {Object} objectWithProcessingStatus - The entity in question (most often a Section, but could be a DocumentSection, IND, or anything with a processing status).
 * @param {DocumentSectionStatus} objectWithStatus.processing_status - The processing status of the entity in question.
 */
const isProcessing = (objectWithProcessingStatus: {
  processing_status: ProcessingStatus;
}) => {
  if (!objectWithProcessingStatus) {
    return false; // Safe handling in the event the object in question is null or undefined.
  }
  return PROCESSING_STATUSES.includes(
    objectWithProcessingStatus.processing_status as string,
  );
};

/**
 * Determines whether an entity with a status is displayable - meaning, no operations are pending on this content and its considered to be
 * valid (non-empty).
 * @param {Object} entityWithStatus - The entity in question (most often a Section, but could be a DocumentSection, IND, or anything with a status).
 * @param {DocumentSectionStatus} entityWithStatus.status - The status of the entity in question.
 */
const isFinishedProcessing = (entityWithStatus: {
  processing_status: ProcessingStatus;
}) => {
  if (!entityWithStatus) {
    return false; // Safe handling in the event the object in question is null or undefined.
  }
  return entityWithStatus.processing_status === null;
};

/**
 * Determines whether an entity with a status is in a drafted state - meaning, the content is in a state where it can be viewed
 * @param entityWithStatus - The entity in question (most often a Section, but could be a DocumentSection, IND, or anything with a status).
 */
const isDrafted = (entityWithStatus: { status: DocumentSectionStatus }) => {
  return DRAFTED_STATUSES.includes(entityWithStatus.status);
};

const hasNoContent = (entityWithStatus: { status: DocumentSectionStatus }) => {
  return NO_CONTENT_STATUSES.includes(entityWithStatus.status);
};

export { hasNoContent, isDrafted, isFinishedProcessing, isProcessing };
