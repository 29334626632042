import {
  IndHttpAdapter,
  IndHttpAdapterInterface,
} from '~/core/adapters/indHttpAdapter';
import { DataFile } from '~/core/domain/types';
import { TreeUtils } from '~/core/lib/treeUtils';
import logger from '~/core/providers/logger';

const fileEndpoints = {
  files: (indId: string, includeImagePreviewUrl: boolean) =>
    `ind-applications/${indId}/files?include_image_preview_url=${includeImagePreviewUrl.toString()}`,
};

const defaultHttpAdapter = new IndHttpAdapter();

const getFiles: ({
  indId,
  includeImagePreviewUrl,
  format,
  indHttpAdapter,
}: {
  indId: string;
  includeImagePreviewUrl: boolean;
  format?: 'flat' | 'tree';
  indHttpAdapter?: IndHttpAdapterInterface;
}) => Promise<Array<DataFile>> = async ({
  indId,
  includeImagePreviewUrl,
  format = 'flat',
  indHttpAdapter,
}): Promise<Array<DataFile>> => {
  const adapter = indHttpAdapter || defaultHttpAdapter;

  const indResponse = await adapter.get<{ data: Array<DataFile> }>({
    endpoint: fileEndpoints.files(indId, includeImagePreviewUrl),
  });

  if (indResponse.error || !indResponse.data) {
    const indError = indResponse.error || { message: 'missing data' };
    logger.logError(indError.message, {
      ...indError,
    });
    return [];
  }

  if (format === 'tree') {
    return indResponse.data?.data as Array<DataFile>;
  }

  let flattenedFiles: Array<DataFile> = [];
  indResponse.data?.data.forEach((file) => {
    const flattenedHierarchy = TreeUtils.flattenTree({
      sourceTree: file,
      options: { childPropName: 'children' },
    });
    flattenedFiles = flattenedFiles.concat(flattenedHierarchy);
  });

  return flattenedFiles as Array<DataFile>;
};

export { getFiles };
