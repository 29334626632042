export type FrontMatterSection = {
  id: string;
  is_document: boolean;
  level: number;
  section_number: string;
  status: string;
  title: string;
  type: string;
  has_toc?: boolean;
  has_lot?: boolean;
  has_lof?: boolean;
  has_loa?: boolean;
};

export const frontMatterArray: FrontMatterSection[] = [
  {
    id: 'FM0',
    is_document: false,
    level: 0,
    section_number: 'ToC',
    status: 'Drafting',
    title: 'TABLE OF CONTENTS',
    type: 'front-matter',
  },
  {
    id: 'FM1',
    is_document: false,
    level: 0,
    section_number: 'LoT',
    status: 'Drafting',
    title: 'LIST OF TABLES',
    type: 'front-matter',
  },
  {
    id: 'FM2',
    is_document: false,
    level: 0,
    section_number: 'LoF',
    status: 'Drafting',
    title: 'LIST OF FIGURES',
    type: 'front-matter',
  },
  // @gward35 7-30-2024 LoA was descoped from the Better Exports initial release
  // issues around saving this brand new content block at the document level
  // are the cause, please refer to the project page to see the branch where the work
  // so far is saved
  // {
  //   id: 'FM3',
  //   is_document: false,
  //   level: 0,
  //   section_number: 'LoA',
  //   status: 'ToDo',
  //   title: 'LIST OF ABBREVIATIONS',
  //   type: 'front-matter',
  // },
];
